// All standard plans (ID: 1, 2, 3, 4, 5, 30) use CC form and PP submit component
// BM Basic Access and Donations use CC form and PP express checkout component
import { cloneDeep } from 'lodash';
import { products as perks } from '~modules/perks/perks.content';
import FirstProjectThumb from '~images/sponsorship/project-1.png';
import SecondProjectThumb from '~images/sponsorship/project-2.png';
import ThirdProjectThumb from '~images/sponsorship/project-3.png';
import SponsorshipProjectThumb from '~images/sponsorship/sponsorship.png';
import { SUBSCRIBE_PATH } from './subscribe-helpers';

export const PRODUCT_IDS = {
  Donation: 40,
  Premium: 2,
  Education: 3,
  PC: 4,
  PatronsBronze: 411,
  PatronsSilver: 412,
  PatronsGold: 413
};

const limited = {
  id: -1, // random ID, this plan does not exist in DB
  name: 'Limited',
  description: '',
  isRecurring: true,
  monthlyPrice: 0,
  yearlyPrice: 0,
  lowerTierId: null,
  productType: 'Plan',
  pricingButtonTitle: 'Support Us',
  pricingButtonIcon: false,
  pricingButtonLink: '/checkout',
  purchaseButtonTitle: 'Purchase Subscription',
  changeLink: SUBSCRIBE_PATH,
  features: [
    {
      list: [
        'Limited access to over 1,000 Torah videos on the weekly Parsha, Jewish holidays, and more',
        "Receive the occasional email about our latest content, upcoming holidays & limited-time offers you won't want to miss!"
      ]
    }
  ],
  planStyles: {
    color: '#808aa8',
    activeColor: '#808aa8'
  },
  footnote: null
};

const premiumProduct = {
  id: PRODUCT_IDS.Premium,
  name: 'Premium',
  description: '',
  isRecurring: true,
  monthlyPrice: 18,
  yearlyPrice: 180,
  monthlyPriceWithFees: 18.84,
  yearlyPriceWithFees: 185.67,
  productType: 'Plan',
  pricingButtonTitle: 'Support Us',
  pricingButtonIcon: true,
  pricingButtonLink: '/checkout',
  purchaseButtonTitle: 'Purchase Subscription',
  hasYearlyDiscount: true,
  hasCoupon: true,
  changeLink: SUBSCRIBE_PATH,
  features: [
    {
      list: [
        'Unlimited streaming of over 1000 Torah videos',
        `Access to exclusive webinars with Rabbi Fohrman`,
        'Printable transcripts of all videos for Shabbat reading',
        'Parsha podcasts and learning guides',
        'Uninterrupted usage of iOS and Android app',
        'Bar And Bat Mitzvah Preparation Course'
      ],
      annualOnlyList: []
    }
  ],
  planStyles: {
    color: '#73B2E8',
    activeColor: '#3d83b6'
  },
  footnote: null
};
const subscriptions = [
  {
    id: 1,
    name: 'Member',
    description: '',
    isRecurring: true,
    monthlyPrice: 9,
    yearlyPrice: 90,
    lowerTierId: null,
    productType: 'Plan',
    pricingButtonTitle: 'Support Us',
    pricingButtonIcon: true,
    pricingButtonLink: '/checkout',
    purchaseButtonTitle: 'Purchase Subscription',
    hasYearlyDiscount: true,
    hasCoupon: true,
    changeLink: SUBSCRIBE_PATH,
    features: [
      {
        list: [
          'Unlimited access to over 1,000 Torah videos on the weekly Parsha, Jewish holidays, and more',
          'Printable transcripts to all videos',
          'Access to the iOS and Android app',
          'Download videos to your mobile device'
        ]
      }
    ],
    planStyles: {
      color: '#76C79D',
      activeColor: '#45966f'
    },
    footnote: null
  },
  premiumProduct,
  {
    id: PRODUCT_IDS.Education,
    name: 'Education',
    description: '',
    isRecurring: true,
    monthlyPrice: 39,
    yearlyPrice: 390,
    yearlyPriceWithFees: 401.94,
    lowerTierId: 2,
    productType: 'Plan',
    pricingButtonTitle: 'Support Us',
    pricingButtonIcon: false,
    pricingButtonLink: '/checkout',
    purchaseButtonTitle: 'Purchase Subscription',
    hasYearlyDiscount: true,
    hasCoupon: true,
    changeLink: SUBSCRIBE_PATH,
    features: [
      {
        list: [
          '30 free Basic accounts, for all students in your classroom',
          'Unlimited streaming of over 1000 Torah videos',
          'Teacher Guides for seamless classroom integration',
          'Access to special educator-only webinars with Rabbi Fohrman',
          'License granted for classroom or group viewing'
        ]
      }
    ],
    planStyles: {
      color: '#f0be6d',
      activeColor: '#bb8e3f'
    },
    footnote: null
  },
  {
    id: PRODUCT_IDS.PC,
    name: 'Patrons Circle',
    description: `If you're in a position to<br/><b>give at the highest level,</b><br/>consider joining the Circle.`,
    isRecurring: true,
    monthlyPrice: 150,
    monthlyPriceWithFees: 154.8,
    yearlyPrice: 1800,
    yearlyPriceWithFees: 1854.01,
    lowerTierId: null,
    productType: 'Plan',
    pricingButtonTitle: 'Learn More',
    pricingButtonIcon: false,
    pricingButtonLink: '/patrons-circle',
    purchaseButtonTitle: 'Purchase Subscription',
    hasYearlyDiscount: false,
    hasCoupon: true,
    changeLink: SUBSCRIBE_PATH,
    features: [
      {
        list: [
          'Unlimited access to our library of over 1,000 Torah videos and podcasts',
          'Live interactive courses led by Rabbi Fohrman',
          'Learn alongside Rabbi Fohrman as he develops his latest Torah research',
          'In-Person gatherings & events',
          'Access to a Biblical Hebrew Course',
          'Complimentary Premium memberships to gift to friends/ family',
          'Dedicated customer-service agent to guide you through your Torah journey',
          'And More!'
        ]
      }
    ],
    planStyles: {
      color: '#F2A271',
      activeColor: '#bc7345'
    },
    footnote: null
  }
];
const barmitzvah = {
  id: 31, // random ID, this plan does not exist in DB
  name: 'BAR & BAT MITZVAH PREP',
  isRecurring: false,
  monthlyPrice: 150,
  yearlyPrice: 150,
  lowerTierId: null,
  productType: 'Order',
  pricingButtonTitle: 'Support Us',
  pricingButtonIcon: false,
  pricingButtonLink: '/checkout',
  purchaseButtonTitle: 'Process Order',
  hasCoupon: true,
  changeLink: '/bar-bat-mitzvah',
  features: [
    {
      title: 'Bar and Bat Mitzvah Prep Course',
      list: [
        'Bar and Bat Mitzvah Prep Videos',
        'Illustrated Study Guide',
        'Parents’ Manual',
        'Exclusive Podcast',
        'Access to Prep Course Facebook Group'
      ]
    }
  ],
  planStyles: {
    color: '#f18744',
    activeColor: '#f18744'
  },
  footnote: 'one-time payment'
};
const oneTimeDonation = {
  id: PRODUCT_IDS.Donation, // random ID, this "plan" does not exist in DB
  name: 'Donation', // one-time donation
  isRecurring: false,
  monthlyPrice: 0, // one-time donation amounts are set on checkout
  yearlyPrice: 0, // we maintain only a single "product" for them all.
  lowerTierId: null,
  productType: 'Order',
  pricingButtonTitle: 'Support Us',
  pricingButtonIcon: false,
  pricingButtonLink: '/checkout',
  purchaseButtonTitle: 'Process Donation',
  hasCoupon: false,
  changeLink: '/donate',
  features: [
    {
      list: ['A 100% Tax Deductible Donation']
    }
  ],
  planStyles: {
    color: '#438dce',
    activeColor: '#438dce'
  },
  footnote: 'one-time payment'
};

export const sponsorships = [
  {
    id: 50, // random ID, this plan does not exist in DB
    name: 'DONATION',
    perkName: 'The Silver Screen with Rabbi Fohrman',
    isRecurring: false,
    monthlyPrice: 0, // one-time donation amounts are set on checkout
    yearlyPrice: 0,
    lowerTierId: null,
    isSponsorship: true,
    sponsorshipProject: 'women_in_tanakh',
    title: 'Women in Tanakh',
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: '/kickstarter-campaign',
    features: [
      {
        list: ['A Tax Deductible Donation', 'Donation to Women In Tanakh']
      }
    ],

    planStyles: {
      color: '#438dce',
      activeColor: '#438dce'
    },
    description: {
      html: `<p>Women in Tanakh play integral, powerful, and sometimes concealed roles, but are often responsible for key turning points in Biblical stories. We think it's time for an Aleph Beta spotlight on the Women in Tanakh! We will explore the lives of great female figures in Tanakh including Sarah, Rebecca, Rachel, Leah, Miriam, Channah, Esther, and Ruth, and uncover their hidden contributions and ever lasting impact. The depth of their heroic actions and often unexpected valor deserve to be unveiled and extensively studied.</>`
    },
    thumbnail: {
      alt: '',
      url: FirstProjectThumb
    },
    footnote: 'one-time payment'
  },

  {
    id: 51,
    name: 'DONATION',
    isRecurring: false,
    monthlyPrice: 0, // one-time donation amounts are set on checkout
    yearlyPrice: 0,
    lowerTierId: null,
    isSponsorship: true,
    title: 'Meaningful Judaism Podcast & YouTube Series',
    sponsorshipProject: 'meaningful_judaism_podcast',
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: '/kickstarter-campaign',
    features: [
      {
        list: [
          'A Tax Deductible Donation',
          'Donation to Meaningful Judaism Podcast & YouTube Series'
        ]
      }
    ],
    planStyles: {
      color: '#438dce',
      activeColor: '#438dce'
    },
    description: {
      html: `<p>This free podcast is the culmination of years of yearning and dreaming! It's an effort to explore the meaning behind the laws and mizvot we practice, call it, “The Jewish Book of Why?”. In this series we’ll cover a wide range of mitzvot, such as Shabbat, Niddah, and Tzitzit. What makes this series special is that it will incorporate the classic Aleph Beta methodology of utilizing a close reading of the Biblical text, to see if the Torah, intended for us to know some of that meaning. The hope is that a deeper understanding of the mizvot will renew our passion and enthusiasm, and hopefully…hopefully, improve our relationship with our Creator. You won’t have to be an Aleph Beta premium subscriber to enjoy this podcast but we could sure use your support to get it off the ground!</>`
    },
    thumbnail: {
      alt: '',
      url: SecondProjectThumb
    },
    footnote: 'one-time payment'
  },
  {
    id: 52,
    name: 'DONATION',
    isRecurring: false,
    monthlyPrice: 0, // one-time donation amounts are set on checkout
    yearlyPrice: 0,
    lowerTierId: null,
    isSponsorship: true,
    title: 'Book Like No Other',
    sponsorshipProject: 'book_like_no_other',
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: '/kickstarter-campaign',
    features: [
      {
        list: ['A 100% Tax Deductible Donation', 'Donation to Book Like No Other']
      }
    ],
    planStyles: {
      color: '#438dce',
      activeColor: '#438dce'
    },
    description: {
      html: `<p>A Book Like No Other is a podcast exclusively dedicated to Rabbi Fohrman where he will showcase his latest and greatest Torah insights. Rather than being connected to the weekly Torah portion or a specific holiday, it will enable Rabbi Fohrman to spend multiple weeks on a dedicated topic and deeply explore some of the Torah’s most incredible secrets. We are all unbelievably excited to see this podcast take shape and come to fruition.</>`
    },
    thumbnail: {
      alt: '',
      url: ThirdProjectThumb
    },
    footnote: 'one-time payment'
  },
  {
    id: 53,
    name: 'DONATION',
    isRecurring: false,
    monthlyPrice: 0, // one-time donation amounts are set on checkout
    yearlyPrice: 0,
    lowerTierId: null,
    isSponsorship: true,
    title: 'Kickstart Our Next Project',
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    sponsorshipProject: 'kickstart-project',
    changeLink: '/kickstarter-campaign',
    features: [
      {
        list: ['A 100% Tax Deductible Donation', 'Donation to Kickstart Our Next Project']
      }
    ],
    planStyles: {
      color: '#438dce',
      activeColor: '#438dce'
    },
    thumbnail: {
      alt: '',
      url: SponsorshipProjectThumb
    },
    footnote: 'one-time payment'
  }
];

const recurringDonations = [
  {
    id: 42,
    name: 'Donation',
    isRecurring: true,
    monthlyPrice: 7,
    monthlyPriceWithFees: 7.51,
    yearlyPrice: 72,
    lowerTierId: null,
    productType: 'Order',
    pricingButtonTitle: 'Support Us',
    pricingButtonIcon: false,
    pricingButtonLink: '/checkout',
    purchaseButtonTitle: 'Process Donation',
    hasCoupon: false,
    changeLink: '/donate',
    features: [
      {
        list: ['A 100% Tax Deductible Donation']
      }
    ],
    planStyles: {
      color: '#438dce',
      activeColor: '#438dce'
    }
  },
  {
    id: 43,
    name: 'Donation',
    isRecurring: true,
    monthlyPrice: 18,
    monthlyPriceWithFees: 18.84,
    yearlyPrice: 180,
    lowerTierId: null,
    productType: 'Order',
    pricingButtonTitle: 'Support Us',
    pricingButtonIcon: false,
    pricingButtonLink: '/checkout',
    purchaseButtonTitle: 'Process Donation',
    hasCoupon: false,
    changeLink: '/donate',
    features: [
      {
        list: ['A 100% Tax Deductible Donation']
      }
    ],
    planStyles: {
      color: '#438dce',
      activeColor: '#438dce'
    }
  },
  {
    id: 44,
    name: 'Donation',
    isRecurring: true,
    monthlyPrice: 36,
    monthlyPriceWithFees: 37.38,
    yearlyPrice: 250,
    lowerTierId: null,
    productType: 'Order',
    pricingButtonTitle: 'Support Us',
    pricingButtonIcon: false,
    pricingButtonLink: '/checkout',
    purchaseButtonTitle: 'Process Donation',
    hasCoupon: false,
    changeLink: '/donate',
    features: [
      {
        list: ['A 100% Tax Deductible Donation']
      }
    ],
    planStyles: {
      color: '#438dce',
      activeColor: '#438dce'
    }
  },
  //  This plan is here in order to have the correct placement on the page without further sorting
  {
    id: 46,
    name: 'Donation',
    isRecurring: true,
    monthlyPrice: 50,
    monthlyPriceWithFees: 51.79,
    yearlyPrice: null,
    lowerTierId: null,
    productType: 'Order',
    pricingButtonTitle: 'Support Us',
    pricingButtonIcon: false,
    pricingButtonLink: '/checkout',
    purchaseButtonTitle: 'Process Donation',
    hasCoupon: false,
    changeLink: '/donate',
    features: [
      {
        list: ['A 100% Tax Deductible Donation']
      }
    ],
    planStyles: {
      color: '#438dce',
      activeColor: '#438dce'
    }
  },
  {
    id: 45,
    name: 'Donation',
    isRecurring: true,
    monthlyPrice: 100,
    monthlyPriceWithFees: 103.28,
    yearlyPrice: 360,
    lowerTierId: null,
    productType: 'Order',
    pricingButtonTitle: 'Support Us',
    pricingButtonIcon: false,
    pricingButtonLink: '/checkout',
    purchaseButtonTitle: 'Process Donation',
    hasCoupon: false,
    changeLink: '/donate',
    features: [
      {
        list: ['A 100% Tax Deductible Donation']
      }
    ],
    planStyles: {
      color: '#438dce',
      activeColor: '#438dce'
    }
  }
];

const payWhatYouCanProducts = [];
for (let i = 1; i <= 36; i += 1) {
  // Let's just parasitize on the existing Premium product
  const product = cloneDeep(premiumProduct);
  // NOTE: corresponds to the ID of the plan in the backend, see backend/migrations/20230130164735_add_pay_what_you_can_subscription_plans.cjs
  product.id = 100 + i;
  product.description = 'Pay What You Can promo';
  product.monthlyPrice = i;
  product.yearlyPrice = i * 12; // NOTE: corresponds to the plan in the backend, confirm with higher-ups!
  product.hasYearlyDiscount = false;
  product.hasCoupon = false;
  product.changeLink = '/pay-what-you-can'; // this page
  product.payPalPaymentDisabled = true;
  payWhatYouCanProducts.push(product);
}
const producerCircleInteractiveCourse = {
  // It must be synchronized with the ID of the record in the `subscriptions` table
  id: 401,
  name: 'The Seven Habits of Highly Intuitive Readers of the Bible',
  description: `Don’t just believe the Bible is profound. Know it.`,
  isRecurring: false,
  monthlyPrice: null,
  monthlyPriceWithFees: null,
  yearlyPrice: 1800,
  yearlyPriceWithFees: null,
  lowerTierId: null,
  productType: 'Plan',
  pricingButtonTitle: 'Learn More',
  pricingButtonIcon: false,
  pricingButtonLink: '/patrons-circle',
  purchaseButtonTitle: 'Get Access',
  hasYearlyDiscount: false,
  hasCoupon: false,
  changeLink: '/patrons-circle',
  features: [
    {
      list: [
        '8 Weeks of live interactive classes led by Rabbi David Fohrman',
        'Course materials and lifetime access to class recordings',
        'Joining the Patrons Circle, an online community of Torah learners',
        'Unlimited access to our library of over 1,000 Torah videos and podcasts',
        'Live interactive courses led by Rabbi Fohrman',
        'Learn alongside Rabbi Fohrman as he develops his latest Torah research',
        'In-Person gatherings & events',
        'Access to a Biblical Hebrew Course',
        'Complimentary Premium memberships to gift to friends/ family',
        'Dedicated customer-service agent to guide you through your Torah journey',
        'And More!'
      ]
    }
  ],
  planStyles: {
    color: '#F2A271',
    activeColor: '#bc7345'
  },
  footnote: null,
  payPalPaymentDisabled: true
};
const patrons = [
  {
    id: PRODUCT_IDS.PatronsBronze,
    name: 'Patron - Bronze tier',
    description: `Unique perks and opportunities just for you`,
    isRecurring: true,
    monthlyPrice: 99,
    monthlyPriceWithFees: null,
    yearlyPrice: 999,
    yearlyPriceWithFees: null,
    lowerTierId: null,
    productType: 'Plan',
    pricingButtonTitle: 'Change your tier',
    pricingButtonIcon: false,
    pricingButtonLink: '/patrons-circle',
    purchaseButtonTitle: 'Join Patron',
    hasYearlyDiscount: false,
    hasCoupon: true,
    changeLink: '/patrons-circle',
    features: [
      {
        list: [
          'Unlimited access to our library of over 1,000 Torah videos and podcasts',
          'Live interactive courses led by Rabbi Fohrman',
          'Learn alongside Rabbi Fohrman as he develops his latest Torah research',
          'In-Person gatherings & events',
          'Access to a Biblical Hebrew Course',
          'Complimentary Premium memberships to gift to friends/ family',
          'Dedicated customer-service agent to guide you through your Torah journey',
          'And More!'
        ]
      }
    ],
    planStyles: {
      color: '#F2A271',
      activeColor: '#bc7345'
    },
    footnote: null
  },
  {
    id: PRODUCT_IDS.PatronsSilver,
    name: 'Patron - Silver tier',
    description: `Unique perks and opportunities just for you`,
    isRecurring: true,
    monthlyPrice: 180,
    monthlyPriceWithFees: null,
    yearlyPrice: 1800,
    yearlyPriceWithFees: null,
    lowerTierId: null,
    productType: 'Plan',
    pricingButtonTitle: 'Change your tier',
    pricingButtonIcon: false,
    pricingButtonLink: '/patrons-circle',
    purchaseButtonTitle: 'Join Patron',
    hasYearlyDiscount: false,
    hasCoupon: true,
    changeLink: '/patrons-circle',
    features: [
      {
        list: [
          'Unlimited access to our library of over 1,000 Torah videos and podcasts',
          'Live interactive courses led by Rabbi Fohrman',
          'Learn alongside Rabbi Fohrman as he develops his latest Torah research',
          'In-Person gatherings & events',
          'Access to a Biblical Hebrew Course',
          'Complimentary Premium memberships to gift to friends/ family',
          'Dedicated customer-service agent to guide you through your Torah journey',
          'And More!'
        ]
      }
    ],
    planStyles: {
      color: '#F2A271',
      activeColor: '#bc7345'
    },
    footnote: null
  },
  {
    id: PRODUCT_IDS.PatronsGold,
    name: 'Patron - Gold tier',
    description: `Unique perks and opportunities just for you`,
    isRecurring: true,
    monthlyPrice: 500,
    monthlyPriceWithFees: null,
    yearlyPrice: 5000,
    yearlyPriceWithFees: null,
    lowerTierId: null,
    productType: 'Plan',
    pricingButtonTitle: 'Change your tier',
    pricingButtonIcon: false,
    pricingButtonLink: '/patrons-circle',
    purchaseButtonTitle: 'Join Patron',
    hasYearlyDiscount: false,
    hasCoupon: true,
    changeLink: '/patrons-circle',
    features: [
      {
        list: [
          'Unlimited access to our library of over 1,000 Torah videos and podcasts',
          'Live interactive courses led by Rabbi Fohrman',
          'Learn alongside Rabbi Fohrman as he develops his latest Torah research',
          'In-Person gatherings & events',
          'Access to a Biblical Hebrew Course',
          'Complimentary Premium memberships to gift to friends/ family',
          'Dedicated customer-service agent to guide you through your Torah journey',
          'And More!'
        ]
      }
    ],
    planStyles: {
      color: '#F2A271',
      activeColor: '#bc7345'
    },
    footnote: null
  }
];
const products = [
  limited,
  ...subscriptions,
  barmitzvah,
  oneTimeDonation,
  ...recurringDonations,
  ...perks,
  ...sponsorships,
  ...payWhatYouCanProducts,
  producerCircleInteractiveCourse,
  ...patrons
];

export const getAllIncludedFeatures = (productId, billingCycle) => {
  const product = products.find(({ id }) => id === productId);
  const allFeatures = cloneDeep(product.features); // NOTE: extremely important to deep clone instead of shallow!

  if (product.lowerTierId) {
    allFeatures.unshift(...getAllIncludedFeatures(product.lowerTierId, billingCycle));
  }

  return allFeatures;
};

export const getProductPrice = (product, isYearly, isPayingFees) => {
  if (isPayingFees) {
    return isYearly ? product.yearlyPriceWithFees : product.monthlyPriceWithFees;
  }

  return isYearly ? product.yearlyPrice : product.monthlyPrice;
};

export const getProductPriceText = (product, isYearly) => {
  if (!product || (isYearly && !product.yearlyPrice) || (!isYearly && !product.monthlyPrice)) {
    return 'Free';
  }

  return isYearly ? `$ ${product.yearlyPrice}/year` : `$ ${product.monthlyPrice}/month`;
};

export const getPriceTextByPlanIdAndPeriod = (subscriptionPlanId, subscriptionPlanPeriod) => {
  if (subscriptionPlanId === null || subscriptionPlanId === undefined) {
    return '';
  }
  const product = products.find(({ id }) => id === subscriptionPlanId);
  const isYearly = subscriptionPlanPeriod === 'year';
  return getProductPriceText(product, isYearly);
};

const roundToTwoPositions = value => Math.round(value * 100) / 100;

// Calculation of fees as Stripe does it: 30c + 2.9% of the charge amount. We deliberately don't calculate complicated percentages.
export const calculateDonationAmountWithFees = amount => roundToTwoPositions(amount * 1.029 + 0.3);

export default products;
